import { createHashRouter, RouterProvider } from "react-router-dom";
import Nav from "./pages/Nav";
import Map from "./pages/Map";

const routers = createHashRouter([
  { path: "project/:type", element: <Map /> },
  { path: "*", element: <Nav /> }
]);

const Route = () => <RouterProvider router={routers} />;
export default Route;