
const angleWrapClassName = "before:absolute after:absolute before:w-2 before:h-2 before:left-0 after:w-2 after:h-2 after:right-0";
export const Angle = ({ style, className = "", title, children, onClick }) => {
  return <div
    title={title}
    style={style}
    onClick={onClick}
    className={
      "bg-[#80BFFF]/20 relative " +
      "before:border-t-2 before:border-l-2 before:border-t-[#80BFFF] before:border-l-[#80BFFF] " +
      " after:border-t-2 after:border-r-2 after:border-t-[#80BFFF] after:border-r-[#80BFFF] after:top-0 " +
      angleWrapClassName + ` ${className}`
    }
  >
    { children }
    <div
      className={
        "before:absolute after:absolute before:bottom-0 after:bottom-0 " +
        "before:border-b-2 before:border-l-2 before:border-b-[#80BFFF] before:border-l-[#80BFFF] " +
        "after:border-b-2 after:border-r-2 after:border-b-[#80BFFF] after:border-r-[#80BFFF] " +
        angleWrapClassName
      }
    />
  </div>
}