import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import school from '../../style/imgs/school.png';
import hospital from '../../style/imgs/hospital.png';
import hhmg from '../../style/imgs/hhmg.png';
import government from '../../style/imgs/government.png';
import enterprise from '../../style/imgs/enterprise.png';
import './index.css';


const Nav = () => {

  const navigate = useNavigate();
  const { current: items } = useRef([
    { key: "school", name: "学校", img: school, url: "/project/school" },
    { key: "hospital", name: "医院", img: hospital, url: "/project/hospital" },
    { key: "hhmg", name: "淏华玛格", img: hhmg, url: "/project/hhmg" },
    { key: "government", name: "政府", img: government, url: "/project/government" },
    { key: "enterprise", name: "企业", img: enterprise, url: "/project/enterprise" }
  ]);

  return <div className="nav w-full h-full flex items-center justify-around px-12 relative">
    {
      items.map(item => <div key={item.key} className='item'>
        <div className='name'>{ item.name }</div>
        <img src={item.img} preview={false} alt="图片" />
        <button className='cornerButton' onClick={() => navigate(item.url)}>
          点击进入
          <div className='bottomAngle'></div>
        </button>
      </div>)
    }

    <div className="title text-5xl absolute top-[80px] left-1/2 -translate-x-1/2 font-medium text-white">
      淏华智慧水务管理平台
    </div>

    <div className="absolute bottom-2 left-1/2 -translate-x-1/2">
      <a href='https://beian.miit.gov.cn/' className='text-[#2492ff] hover:text-[#2492ffCC]'>粤ICP备2023058423号-1</a>
    </div>
  </div>
}

export default Nav;