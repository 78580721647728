import { useMemo, useRef, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Angle } from '../../components/Wrap';
import headBg from '../../style/imgs/head_bg.png';
import school from '../../style/imgs/school.png';
// import hospital from '../../style/imgs/hospital.png';
import hhmg from '../../style/imgs/hhmg.png';
import government from '../../style/imgs/government.png';
import enterprise from '../../style/imgs/enterprise.png';
import mapBg from '../../style/imgs/mapBg.png';
import "./index.css";

const Map = () => {

  const { type } = useParams();
  const navigate = useNavigate();

  const [selectedProject, setSelectedProject] = useState();

  const { current: projects } = useRef({
    school: [
      { key: "school12", icon: school, name: "商丘职业技术学院", url: "http://www.zghaohua.com:83/", position: "top-[25%] left-[65%]" },
      { key: "school14", icon: school, name: "惠州工程职业学院", url: "http://www.zghaohua.com:82", position: "top-[57.5%] left-[64.8%]" },
      { key: "school13", icon: school, name: "惠州学院", url: "http://www.zghaohua.com:81", position: "top-[56%] left-[67%]" },
      { key: "school15", icon: school, name: "广东梅州职业技术学院", url: "http://www.zghaohua.com:84", position: "top-[53.5%] left-[67.8%]" },
      { key: "school16", icon: school, name: "甘肃民族师范学院", url: "http://www.zghaohua.com:85", position: "top-[22.5%] left-[53%]" },
      { key: "school17", icon: school, name: "沧洲一中", url: "http://www.zghaohua.com:87", position: "top-[9%] left-[69.5%]" },
      { key: "school18", icon: school, name: "河源职业技术学院", url: "http://www.zghaohua.com:88", position: "top-[54%] left-[65.5%]" },
      { key: "school19", icon: school, name: "藤县七中", url: "http://www.zghaohua.com:89", position: "top-[15%] left-[73.5%]" },
      { key: "school20", icon: school, name: "汉口学院", url: "http://www.zghaohua.com:91", position: "top-[30.5%] left-[66%]" },
      { key: "school22", icon: school, name: "广东科学职业技术学院", url: "http://www.zghaohua.com:93", position: "top-[61%] left-[63.5%]" },
      { key: "school21", icon: school, name: "广东食品药品职业学院", url: "http://www.zghaohua.com:94", position: "top-[61%] left-[65.5%]" },
      { key: "school23", icon: school, name: "广州星海音乐学院", url: "http://www.zghaohua.com:96", position: "top-[61%] left-[63%]" },
      { key: "school24", icon: school, name: "藤县六中", url: "http://www.zghaohua.com:97", position: "top-[15%] left-[72%]" },
      { key: "school25", icon: school, name: "安徽汽车职业技术学院", url: "http://www.zghaohua.com:98", position: "top-[32%] left-[72%]" },
      { key: "school1", icon: school, name: "合肥工业大学", url: "http://anhuizetao.com", position: "top-[27%] left-[72.5%]" },
      { key: "school2", icon: school, name: "安徽工业大学", url: "http://anhuizetao.com:81/", position: "top-[33%] left-[74%]" },
      { key: "school3", icon: school, name: "河南财经政法大学", url: "", position: "top-[21%] left-[67%]" },
      { key: "school4", icon: school, name: "广东外语艺术职业学院", url: "http://114.55.177.73:9100/", position: "top-[56%] left-[69%]" },
      { key: "school5", icon: school, name: "珠海城市职业技术学院", url: "", position: "top-[56%] left-[71%]" },
      { key: "school6", icon: school, name: "广东司法警官职业学院", url: "", position: "top-[60%] left-[64%]" },
      { key: "school7", icon: school, name: "深圳职业技术学院（建设中）", url: "", position: "top-[59%] left-[67%]" },
      { key: "school8", icon: school, name: "深圳技术学院（建设中）", url: "", position: "top-[59%] left-[69%]" },
      { key: "school9", icon: school, name: "梧州一中", url: "http://114.55.177.73:9999/", position: "top-[53%] left-[60%]" },
      // { key: "school10", icon: school, name: "湖南城建职业技术学院", url: "", position: "top-[41%] left-[64%]" },
      { key: "school11", icon: school, name: "云南大学", url: "http://113.55.126.59/", position: "top-[52%] left-[46%]" },
    ],
    hospital: [],
    hhmg: [
      { key: "hhmg1", icon: hhmg, name: "深圳淏华玛格节能环保科技有限公司", url: "", position: "top-[59%] left-[67%]" }
    ],
    government: [
      { key: "government1", icon: government, name: "深圳市建筑工务署", url: "", position: "top-[56%] left-[70%]" },
      { key: "government2", icon: government, name: "深圳城管局", url: "", position: "top-[54%] left-[66%]" },
      { key: "government3", icon: government, name: "广东科学馆", url: "", position: "top-[58%] left-[65%]" },
      { key: "government4", icon: government, name: "深圳南山规划局", url: "", position: "top-[59%] left-[67%]" },
      { key: "government5", icon: government, name: "深圳博物馆", url: "", position: "top-[59%] left-[69%]" },
      { key: "government6", icon: government, name: "潮州供水枢纽", url: "http://114.55.177.73/", position: "top-[60.5%] left-[64%]" }
    ],
    enterprise: [
      { key: "enterprise1", icon: enterprise, name: "雁洋供水公司", url: "", position: "top-[54%] left-[66%]" }
    ]
  });

  const { current: headLeft } = useRef([
    { text: "< 返回", url: "/" },
    { text: "", url: "" },
    { text: "学校", url: "/project/school" },
    { text: "医院", url: "/project/hospital" },
  ]);

  const { current: headRight } = useRef([
    { text: "淏华玛格", url: "/project/hhmg" },
    { text: "政府", url: "/project/government" },
    { text: "企业", url: "/project/enterprise" },
    { text: "", url: "" },
  ]);

  /** 获取表头块 */
  const getHeadBloack = (datas, whapClass, contentClass) => {
    return datas.map((d, i) => {
      let className = "";
      if(d.url) className = " cursor-pointer hover:bg-[#66A3FF]/80";
      return <Angle
        key={`${datas}-item-${i}`}
        className={whapClass + className}
        onClick={() => d.url && navigate(d.url) }
        style={d.url.includes(type) ? { backgroundColor: "rgba(102, 163, 255, .8)" } : {}}
      >
        <div className={`h-full text-center ${contentClass}`}>
          { d.text }
        </div>
      </Angle>
    })
  }

  /** 项目 */
  const Content = useMemo(() => {
    return projects[type].map((p, i) => {
      const isSelected = selectedProject === p.key;
      return <div
      key={`content-item-${i}`}
      onMouseOver={() => setSelectedProject(p.key)}
      onMouseOut={() => setSelectedProject(undefined)}
      className={`contentItem w-80 h-32 p-3 flex items-center gap-4 cursor-pointer bg-[#1C1C1C]/40 hover:bg-[#246BB3]/80${ isSelected ? " bg-[#246BB3]/80" : "" }`}
    >
      <img src={p.icon} alt="图标" className="w-16 h-16" />
      <div className="flex-grow">
        <div className="pb-4 text-lg">{ p.name }</div>
        <Angle
          title={p.url}
          className="w-24 h-8 leading-8 text-center cursor-pointer hover:bg-[#66A3FF]/80"
          onClick={() => {
            // window.open(p.url || "http://nh2.yunjichaobiao.com/login.html?r=1684203555181");
            // window.location.href = p.url || "http://nh2.yunjichaobiao.com/login.html?r=1684203555181";
            window.location.assign(p.url || "http://nh2.yunjichaobiao.com/login.html?r=1684203555181");
          }}
        >
          进入系统
        </Angle>
      </div>
    </div>;
    });
  }, [projects, selectedProject, type]);
  /** 点位 */
  const PointList = useMemo(() => {
    return projects[type].map(p => <Point
      position={p.position}
      onMouseOver={() => setSelectedProject(p.key)}
      onMouseOut={() => setSelectedProject(undefined)}
      className={p.key === selectedProject ? "point-selected" : ""}
    />);
  }, [projects, selectedProject, type]);
  return <div className="map w-full h-full">
    <div className="w-full h-14 grid grid-cols-3 relative">
      <div className="pt-6 pb-1 pl-4 grid grid-cols-4 gap-4">
        { getHeadBloack(headLeft, "skew-x-[25deg]", "-skew-x-[25deg]") }
      </div>
      <div className="text-center text-3xl leading-[56px]">
        淏华智慧水务管理平台
      </div>
      <div className="pt-6 pb-1 pr-4 grid grid-cols-4 gap-4">
        { getHeadBloack(headRight, "-skew-x-[25deg]", "skew-x-[25deg]") }
      </div>
      <img src={headBg} alt="背景" className="w-full h-full absolute top-0 left-0 bg-[#1C1C1C]/40 -z-[1]" />
    </div>
    <div className="content w-full flex flex-wrap content-start p-4 pb-0 overflow-x-hidden overflow-y-auto relative">
      { PointList }
      <img src={mapBg} alt="背景" className="w-full h-full absolute left-0 top-0 -z-[1]" />
    </div>
    <div className="content w-full flex flex-wrap content-start p-4 pb-0 overflow-x-hidden overflow-y-auto absolute left-0 top-14">
      { Content }
    </div>

    <div className="absolute bottom-2 left-1/2 -translate-x-1/2 text-black">
      <a href='https://beian.miit.gov.cn/' className='text-[#2492ff] hover:text-[#2492ffCC]'>粤ICP备2023058423号-1</a>
    </div>
  </div>
}

const Point = ({ position, onMouseOver, onMouseOut, className }) => {

  return <div
    onMouseOut={onMouseOut}
    onMouseOver={onMouseOver}
    className={`w-6 h-36 cursor-pointer absolute ${position} ${className}`}
  >
    <div className="h-36 w-6 bg-gradient-to-b from-[#FD891500] to-[#FD891566] translate-y-1" />
    <div
      className={
        "w-6 h-3 bg-[#FD8915] rounded-[100%] relative " +
        "after:w-full after:h-full after:border after:border-[#FD8915] after:scale-[1.5] after:absolute after:rounded-[100%]"
      }
    />
  </div>
}

export default Map;